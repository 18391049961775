<template>
    <el-dialog title="查看借阅申请" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible"
               class="collectForm dialogTable" @close="closeForm()">
        <div v-loading="loading" class="formBox">
            <el-form size="small" :inline="true" :model="inputForm" ref="inputForm"
                     label-width="180px" disabled class="recordCollect">
                <el-form-item label="借阅单号：" prop="borrowCodeNumber">
                    <el-input v-model.trim="inputForm.borrowCodeNumber"></el-input>
                </el-form-item>
                <el-form-item label="借阅人姓名：" prop="borrowUserIdName">
                    <el-input v-model.trim="inputForm.borrowUserIdName"></el-input>
                </el-form-item>
                <el-form-item label="手机：" prop="telPhone">
                    <el-input v-model.trim="inputForm.telPhone"></el-input>
                </el-form-item>
                <el-form-item label="借阅人单位：" prop="unit">
                    <el-input v-model.trim="inputForm.unit"></el-input>
                </el-form-item>
                <el-form-item label="借阅时间：" prop="borrowTime">
                    <el-date-picker class="w100i"
                                    v-model="inputForm.borrowTime" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="期限：" prop="deadline">
                    <el-date-picker class="w100i"
                                    v-model="inputForm.deadline" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="借阅目的：" prop="borrowPurpose">
                    <el-select class="w100i" v-model="inputForm.borrowPurpose"
                               clearable placeholder="请选择">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('borrow_purpose')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" prop="remark" class="rowBox">
                    <el-input
                            maxlength="200"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入备注信息。（限200字）"
                            v-model="inputForm.remark">
                    </el-input>
                </el-form-item>
                <el-descriptions title="借阅档案信息"></el-descriptions>
                <el-form-item label="档案：" prop="list" class="rowBox">
                    <el-table :data="inputForm.list" size="small" max-height="300" class="table m_t1"
                              v-if="inputForm.list.length">
                        <el-table-column prop="name" label="借阅卷宗" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>{{ scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="180" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="view(scope.row, 'view')" :disabled="false">
                                    详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">关闭</el-button>
            <el-button size="small" type="primary" @click="lastOrNext(0)"
                       v-noMoreClick v-if="pageType != 'workbench'">上一条
            </el-button>
            <el-button size="small" type="primary" @click="lastOrNext(1)"
                       v-noMoreClick v-if="pageType != 'workbench'">下一条
            </el-button>
            <el-button size="small" type="primary" @click="audit()"
                       v-if="inputForm.auditState == '1' && userAuditState == '1'" v-noMoreClick>审核
            </el-button>
        </div>
        <RecordDetails ref="recordDetails"></RecordDetails>
        <AuditForm ref="auditForm" @refreshDataList="getAudit()"></AuditForm>
    </el-dialog>
</template>

<script>
    import RecordDetails from '../../components/recordDetails'
    import AuditForm from '../../components/auditForm'

    export default {
        components: {RecordDetails, AuditForm},
        data() {
            return {
                visible: false,
                loading: false,
                inputForm: {
                    id: '',
                    borrowCodeNumber: '',
                    borrowUserIdName: '',
                    telPhone: '',
                    unit: '',
                    unitId: '',
                    borrowTime: '',
                    deadline: '',
                    borrowPurpose: '',
                    auditState: '',
                    remark: '',
                    list: [],
                },
                listSearchForm: [],
                userAuditState: '0',
                pageType: '',
            }
        },
        methods: {
            init(id, searchForm, type) {
                this.pageType = type
                this.listSearchForm = searchForm
                if (id) {
                    this.inputForm.id = id
                }
                this.loading = false
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    this.getInfo()
                })
            },
            // 获取审核状态
            findUserAuditState() {
                this.$axios(this.api.record.findUserAuditState, {
                    borrowType: '3',
                    id: this.inputForm.id
                }).then((res) => {
                    if (res.status) {
                        this.userAuditState = res.data
                    }
                })
            },
            // 获取详情
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.entityDetail + '/' + this.inputForm.id).then((res) => {
                    if (res.status) {
                        this.findUserAuditState()
                        this.inputForm = this.recover(this.inputForm, res.data)
                    }
                    this.loading = false
                })
            },
            // type 0上一条, 1下一条
            lastOrNext(type) {
                this.loading = true
                this.listSearchForm.indexType = type
                this.$axios(this.api.record.entityLastOrNext, this.listSearchForm, 'post').then((res) => {
                    if (res.status && res.data.index) {
                        this.listSearchForm.index = res.data.index
                        this.inputForm = this.recover(this.inputForm, res.data)
                    } else {
                        this.$message.error(`已是${type == 0 ? '第' : '最后'}一条`);
                    }
                    this.loading = false
                })
            },
            // 审核
            audit() {
                if (!this.hasPermission('interior:auditor:audit')) {
                    this.$message.info('暂无审核权限，请联系管理员！')
                    return
                }
                this.$refs.auditForm.init([this.inputForm.id], this.inputForm, 3)
            },
            // 查看
            view(row, method) {
                if (row.archivesDelFag == 1) {
                    this.$message.error('该档案已删除！')
                    return
                }
                this.$refs.recordDetails.init(row, method, row.archivesBasicDataId, 'archivesDataId')
            },
            // 审核后刷新
            getAudit() {
                this.getInfo()
            },
            // 关闭
            closeForm() {
                this.visible = false
                this.$emit('refreshDataList')
            },
        },
    }
</script>

<style scoped>

</style>
