<template>
    <el-dialog :title="title" width="400px" :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm" label-width="120px" label-position="top">
            <el-form-item label="请填写审核建议：" prop="suggest">
                <el-input v-model.trim="inputForm.suggest" :rows="5" type="textarea" maxlength="100" placeholder="请输入内容（100字内）"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                // 通过驳回
                visible: false,
                title: '',
                auditType: '', // 2通过，3驳回
                inputForm: {
                    suggest: '',
                },
                dataRule: {
                    suggest: [
                        {required: true, message: '审核建议不能为空', trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            init(num, inputForm) {
                this.inputForm = inputForm
                this.auditType = num
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            // 确定
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                      this.$axios(this.api.original.thematiclibrarypublishExamine, {
                        'publishState': this.auditType,
                        'thematicId': this.inputForm.thematicId,
                        'id': this.inputForm.id,
                         'suggest': this.inputForm.suggest,
                      }, 'put').then(data => {
                        if (data.status) {
                            this.$message.success(`${this.auditType == '2' ? '通过' : '驳回'}成功`)
                            this.handleClose();
                            this.$emit('handleClose')
                        }
                      })
                    }
                })
            },
            handleClose() {
                this.visible = false
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
        },
    }
</script>

<style scoped>

</style>
