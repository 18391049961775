<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :fullscreen="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      class="dialogTable"
      :before-close="handleClose">
    <el-row :gutter="20">
      <el-col :span="showListtew ? 4 : 1" :class="['p_r', showListtew ? '' : 'el-col-unfold','leftHeighe']">
        <div :class="['unfoldClassifyBtn', showListtew ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showListtew = !showListtew"></div>
        <div class="lump" v-show="showListtew">
          <div class="catalogueBox rulesBox">
            <el-table
                v-loading="columnLoading"
                :data="catalogueList"
                ref="catalogueTable"
                size="small"
                :show-header=false
                height="calc(100vh - 164px)"
                class="table" highlight-current-row
                @current-change="directorySelection">
              <el-table-column prop="total" label="资源鉴定"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col :span="showListtew ? 20 : 24" :class="[showListtew ? '' : 'el-col-unfold','leftHeighe']">
        <div class="lump baioge">
          <el-form class="query-form" @submit.native.prevent @keyup.enter.native="getFileList(1,1)" :inline="true" size="small" ref="inputForm" :model="searchForm"
                   label-width="100px">
            <el-form-item label="资源名称" prop="name">
              <el-input size="small" maxlength="66" clearable v-model.trim="searchForm.name"
                        placeholder="请输入资源名称"></el-input>
            </el-form-item>
            <el-form-item prop="name">
              <el-button type="primary" @click="getFileList(1,1)" size="small"
                         icon="el-icon-search">查询
              </el-button>
              <el-button @click="resetForm()" size="small"
                         icon="el-icon-refresh-right">
                重置
              </el-button>
            </el-form-item>
          </el-form>
          <div style="margin-bottom: 10px">
            <AdvancedQuery ref="advancedQuery" :moduleId="threeModuleId"
                           @getDataList="atlGetDataList"></AdvancedQuery>
          </div>


          <el-table
              :data="dataList"
              v-loading="loading"
              size="small"
              ref="multipleTable"
              height="calc(100vh - 295px)"
              :row-key="'id'"
              @selection-change="selectionChangeHandle"
              class="table">
            <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="thumb_path" width="110" label="缩略图">
              <template slot-scope="scope">
                <el-image class="infoImg" :src="scope.row.thumb_path" fit="contain">
                  <el-image slot="placeholder" fit="contain" class="infoImg"
                            :src="require('@/assets/img/thumbnail.png')"></el-image>
                  <el-image slot="error" fit="contain" class="infoImg"
                            :src="require('@/assets/img/thumbnail.png')"></el-image>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="resource_name"
                label="资源名称">
            </el-table-column>
            <el-table-column prop="resource_type" label="资源类型" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ $dictUtils.getDictLabel("resource_type", scope.row.resource_type, '-') }}
              </template>
            </el-table-column>

            <el-table-column prop="resource_foshot" show-overflow-tooltip label="资源简称">
            </el-table-column>
            <el-table-column prop="resource_desc" label="资源描述" show-overflow-tooltip/>
            <el-table-column
                prop="resource_title" width="120"
                show-overflow-tooltip
                label="主题标题 "></el-table-column>
            <el-table-column
                prop="resource_source" width="120"
                show-overflow-tooltip
                label="资源来源 ">
            </el-table-column>
            <el-table-column
                prop="written_time" width="120"
                show-overflow-tooltip
                label="成文时间">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称"></el-table-column>
            <el-table-column show-overflow-tooltip prop="file_count"
                             label="文件数量"></el-table-column>
            <el-table-column show-overflow-tooltip prop="file_size"
                             label="文件大小"></el-table-column>
            <el-table-column fixed="right" width="180" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="relatedTogether(scope.row)">关联专题库
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="text_center">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="text_center" style="margin-top: 10px">
      <el-button size="small" @click="handleClose()">关闭</el-button>
      <el-button size="small" type="primary" @click="relatedTogether()" :disabled="!dataListSelect.length"
                 v-no-more-click>关联专题库
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import AdvancedQuery from "@/components/advancedQuery/advancedQuery.vue";

export default {
  name: "correlation",
  props:{
    pagetype:{
      type:String,
      default:'',
    }
  },
  components: {AdvancedQuery},
  data() {
    return {
      title: '',
      dialogVisible: false,
      selectIds: [],

      columnSrh: '',
      showListtew:true,
      columnLoading: false,
      catalogueList: [
        {
          value: 0,
          total: '三维资源',
        },
        {
          value: 1,
          total: '图片资源',
        },
        {
          value: 2,
          total: '音频数据',
        },
        {
          value: 3,
          total: '视频数据',
        },
        {
          value: 4,
          total: '出版物数据',
        },
        {
          value: 5,
          total: '文献数据',
        },
        {
          value: 6,
          total: '文件资料',
        },
        {
          value: 7,
          total: '其它资源',
        },
      ],

      searchForm: {
        name: '',
      },

      columeId: '',
      loading: false,
      dataList: [],
      dataListSelect: [],
      current: 1,
      size: 10,
      total: 0,

      threeModuleId: "946049961995272193",   // 当前请求拖拽排序数据id
      tableHead: [],

      threeType: '0',
      listType: '',
      onScene: false, // 是否正在使用场景
      dataListQuery: [], // 临时储存场景
      row: {},
    }
  },

  mounted() {
    // this.getHead();
  },

  methods: {
    init(row) {
      this.row = row
      this.dialogVisible = true
      if(this.pagetype == 'disseminate'){ //宣传报道专题库
        this.catalogueList[0] = {
          value: 0,
          total: '全部',
          moduleId: "946049961995272192",
        }
      }else {
        this.catalogueList[0] = {
          value: 0,
          total: '三维资源',
          moduleId: "946049961995272192",
        }
      }
      this.$nextTick(() => {
        this.autoHighlight();
        this.getFileList(1, 1);
      })
    },

    //获取表头数据
    getHead() {
      this.tableHead = [];
      this.$axios(this.api.sys.getByOriginalId + this.threeModuleId, '', "get").then((res) => {
        if (res.status) {
          this.tableHead = res.data.filter(item => item.logicType == 0)
          this.$nextTick(() => {
            this.$refs.catalogueTable.doLayout()
          })
        }
      })
    },

    resetForm() {
      this.searchForm.name = ''
      this.onScene = false
      this.dataListQuery = []
      this.$refs.advancedQuery.addForm.sceneId = ''
      this.$nextTick(() => {
        this.onScene = false
        this.getFileList(1, 1, -1)
      })
    },

    // 获取高级查询条件
    getDataList(data) {
      if (data != null && data.sceneId != null && data.sceneId != '') {
        this.onScene = true
      } else {
        this.onScene = false
      }
      if (data.clearCurrent != null && data.clearCurrent == true) {
        this.current = 0
      }
      this.dataListQuery = data
      data.size = this.size
      data.current = this.current
      data.type = (this.pagetype == 'disseminate' && this.threeType == 0) ? '' : this.threeType
      data.three =this.pagetype == 'disseminate'?1:0
      data.searchNumOrName = this.searchForm.name
      data.thematicId = this.row.id
      this.$axios(this.api.rcPage.queryResourcesList, JSON.stringify(data), 'post').then(res => {
        if (res && res.status) {
          this.dataList = res.data.records;
          this.total = parseInt(res.data.total)
        }
      })
    },

    //获取资源列表
    //type: -1 时为重置不需要考虑高级筛选
    getFileList(type1, dividePage, type) {
      if (type1 == 1) {
        this.current = 1
      }
      if (dividePage == 1) {
        this.$refs.multipleTable.clearSelection()
      }
      if (type != -1) {
        if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
          this.getDataList(this.dataListQuery)
          this.loading = false
          return
        }
      }
      this.loading = true;
      this.dataListQuery.sceneId = ''
      this.$axios(this.api.original.thematiclibraryresourceList2, {
        'current': this.current,
        'size': this.size,
        'resourceType': (this.pagetype == 'disseminate' && this.threeType == 0) ? '' : this.threeType,
        'thematicId': this.row.id,
        'resourceNameOrId': this.searchForm.name,
        'three':this.pagetype == 'disseminate'?1:0,
      }, 'get').then(res => {
        if (res.status) {
          this.loading = false;
          this.dataList = res.data.records;
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getFileList()
          }
        } else {
          this.$message.error('查询失败');
        }
      })
    },

    //获取元数据id
    // getMetadataId() {
    //   this.$axios(this.api.original.metaDataDirectoryList, {
    //     'resourceName': this.resourceName,
    //     'current': this.threePageNo,
    //     'size': this.threePageSize,
    //   }, 'get').then(data => {
    //     if (data.status) {
    //       this.metadataId = data.data.records[0].id;
    //     }
    //   })
    // },

    //左边目录页默认高亮第一行
    autoHighlight() {
      this.$refs.catalogueTable.setCurrentRow(this.catalogueList[0])
    },

    //左边列表选择
    directorySelection(news) {
      this.onScene = false

      this.dataListQuery.queryConditions = []
      this.searchForm.name = ''
      if (news.value == 0) { //三维资源
        this.threeType = 0
        this.listType = 'three';
      } else if (news.value == 1) { //图片资源
        this.threeType = 1;
        this.listType = 'picture';
      } else if (news.value == 2) { //音频数据
        this.threeType = 2
        this.listType = 'audio';
      } else if (news.value == 3) { //视频数据
        this.threeType = 3
        this.listType = 'video';
      } else if (news.value == 4) { //出版物数据
        this.threeType = 4
        this.listType = 'publicationData';
      } else if (news.value == 5) { //文献数据
        this.threeType = 5
        this.listType = 'literatureData';
      } else if (news.value == 6) { //文件资料
        this.threeType = 6
        this.listType = 'document';
      } else if (news.value == 7) { //其他资源
        this.threeType = 7
        this.listType = 'other';
      }
      this.getFileList(1, 1);
      // this.getMetadataId();
    },

    // 高级查询
    atlGetDataList(data) {
      this.getDataList(data)
    },

    //关联装题库
    relatedTogether(row) {
      let ids = []
      if (row) {
        ids = [row.id]
      } else {
        ids = this.dataListSelect.map(item => {
          return item.id
        })
      }
      this.$confirm(`确定将所选资源关联到对应专题库中？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.original.thematiclibraryresourceSave, {
          resourceIds: ids,
          thematicId: this.row.id,
        }, 'post').then((res) => {
          if (res.status) {
            this.$message.success('关联成功')
            this.$emit('refresh')
            this.handleClose(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    // 点击栏目
    handleCurrentChange(val) {
      this.columeId = val.id
      this.resetForm()
    },


    // 多选
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.getFileList(1, 1);
      }
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.getFileList('', 1);
      }
    },

    handleClose(num) {
      if (!num) {
        this.$confirm(`确定后，将不会关联您所选中的资源数据到专题库中`, '确定关闭', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dialogVisible = false
        })
      } else {
        this.dialogVisible = false
      }
    },
  },
}
</script>

<style scoped>
.lump {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
  border: 1px solid #E9E9E9;
  border-radius: 10px;
  padding: 20px;
}

.infoImg{
  width: 110px;
  height: 110px;
}

.unfoldClassifyBtn{
  right: 10px;
}
</style>
